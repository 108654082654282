import firstImg from "./Components/Images/corn-field-gcbc61633f_1920 (1).jpg";
import secondImg from "./Components/Images/pexels-cottonbro-4911726 (1).jpg";
import thirdImg from "./Components/Images/tilapia-g86f0d33d7_1920 (1).jpg"
const data = [
    {
        id:1,
        image: firstImg,
    },

    {
        id:2,
        image:secondImg,
    },

    {
        id:3,
        image: thirdImg,
    }
]

export default data